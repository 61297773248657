<template>
    <div class="container">
        <el-form :model="dataForm" ref="dataForm" label-position="top">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="小程序首页">
                        <div id="editor" style="width: 375px;"></div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="达人加盟介绍">
                        <div id="editor1" style="width: 375px;"></div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item>
                <el-button v-if="$perm('league_PlatformIndex_edit')" @click="doSave" :loading="loading" type="primary">保
                    存 配 置
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import E from 'wangeditor'

export default {
    name: "Index",
    data: function () {
        return {
            loading: false,
            dataForm: {
                content: null,
                order: null
            },
            editor: null,
            editor1: null,
        }
    },
    mounted: function () {
        this.initEditor()
    },
    methods: {
        initEditor: function () {
            this.editor = new E("#editor")
            this.editor.config.height = 667
            // 配置 onchange 回调函数
            this.editor.config.onchange = text => {
                this.dataForm.content = text
            }
            this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
                const formData = new FormData()
                for (const file of resultFiles) {
                    formData.append("files[]", file)
                }
                this.$helper._upload(this, 'resource.upload.images', formData, resp => {
                    for (const file of resp.data) {
                        insertImgFn(file.src)
                    }
                })
            }
            this.editor.config.onchangeTimeout = 500
            this.editor.create()
            this.editor1 = new E("#editor1")
            this.editor1.config.height = 667
            // 配置 onchange 回调函数
            this.editor1.config.onchange = text => {
                this.dataForm.order = text
            }
            this.editor1.config.customUploadImg = (resultFiles, insertImgFn) => {
                const formData = new FormData()
                for (const file of resultFiles) {
                    formData.append("files[]", file)
                }
                this.$helper._upload(this, 'resource.upload.images', formData, resp => {
                    for (const file of resp.data) {
                        insertImgFn(file.src)
                    }
                })
            }
            this.editor1.config.onchangeTimeout = 500
            this.editor1.create()
            this.getConfig()
        },
        getConfig: function () {
            this.$helper._get(this, 'league.IndexSetting.config', {}, resp => {
                this.dataForm = resp.data
                this.editor.cmd.do('insertHTML', resp.data.content)
                this.editor1.cmd.do('insertHTML', resp.data.order)
            })
        },
        doSave: function () {
            this.$helper._post(this, 'league.IndexSetting.update', {setting: JSON.stringify(this.dataForm)}, resp => {
                this.$message.success(resp.message)
            })
        }
    }
}
</script>

<style scoped>

</style>
